/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-4">
            <h3 class="font-bold mb-4">Port List</h3>
            <vue-draggable-container id="wordBank" class="list wordBank">
              <h5 class="dragBlock font-semibold cursor-pointer ports-block" v-for="port in ports" :key="port">{{ port }}</h5>
            </vue-draggable-container>
          </vx-card>
          <vx-card class="" title="Page Switcher">
            <vs-button
              color="success"
              type="filled"
              icon-pack="feather"
              icon="icon-arrow-right-circle"
              style="width: 100%; z-index: 1"
              class="font-bold flex"
              button
              size="large"
              @click="next_page"
              v-if="currentPage === 0"
              >Next Page
            </vs-button>
            <vs-button
              color="danger"
              type="filled"
              icon-pack="feather"
              icon="icon-arrow-left-circle"
              style="width: 100%"
              class="font-bold"
              button
              size="large"
              @click="previous_page"
              v-else
              >Previous Page
            </vs-button>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <div class="grid grid-cols-2 gap-4" :style="{ display: currentPage === 0 ? '' : 'none!important' }">
            <div class="answer-container">
              <h1 class="heading-28 mb-2">File Transfer</h1>
              <div class="grid grid-cols-2 gap-4">
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">FTP<br /></p>
                    </div>
                  </div>
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input0"></vue-draggable-container>
                </div>
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">TFTP<br /></p>
                    </div>
                  </div>
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input1"></vue-draggable-container>
                </div>
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">SFTP<br /></p>
                    </div>
                  </div>
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input2"></vue-draggable-container>
                </div>
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">SCP<br /></p>
                    </div>
                  </div>
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input3"></vue-draggable-container>
                </div>
              </div>
            </div>
            <div class="answer-container">
              <h1 class="heading-28 mb-2">Email</h1>
              <div class="grid grid-cols-2 gap-4">
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">SMTP<br /></p>
                    </div>
                    <vue-draggable-container
                      class="answerStandard dropzone dropzoneStandard answer-bg"
                      id="input4"
                    ></vue-draggable-container>
                  </div>
                </div>
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">POP3<br /></p>
                    </div>
                    <vue-draggable-container
                      class="answerStandard dropzone dropzoneStandard answer-bg"
                      id="input5"
                    ></vue-draggable-container>
                  </div>
                </div>
                <div class="p-2 answer-box col-span-2">
                  <div class="center">
                    <p class="port-question-title">IMAP4<br /></p>
                  </div>
                  <vue-draggable-container class="answerStandard dropzone dropzoneStandard answer-bg" id="input6"></vue-draggable-container>
                </div>
              </div>
            </div>
            <div class="answer-container">
              <h1 class="heading-28 mb-2">Authentication</h1>
              <div class="grid grid-cols-2 gap-4">
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">Kerberos<br /></p>
                    </div>
                    <vue-draggable-container
                      class="answerStandard dropzone dropzoneStandard answer-bg"
                      id="input7"
                    ></vue-draggable-container>
                  </div>
                </div>
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">TACACS+<br /></p>
                    </div>
                    <vue-draggable-container
                      class="answerStandard dropzone dropzoneStandard answer-bg"
                      id="input8"
                    ></vue-draggable-container>
                  </div>
                </div>
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">Radius<br /></p>
                    </div>
                    <vue-draggable-container
                      class="answerStandard dropzone dropzoneStandard answer-bg"
                      id="input9"
                    ></vue-draggable-container>
                  </div>
                </div>
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">LDAP<br /></p>
                    </div>
                    <vue-draggable-container
                      class="answerStandard dropzone dropzoneStandard answer-bg"
                      id="input10"
                    ></vue-draggable-container>
                  </div>
                </div>
                <div class="p-2 answer-box col-span-2">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">sLDAP<br /></p>
                    </div>
                    <vue-draggable-container
                      class="answerStandard dropzone dropzoneStandard answer-bg"
                      id="input11"
                    ></vue-draggable-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="answer-container">
              <h1 class="heading-28 mb-2">Internet</h1>
              <div class="grid grid-cols-2 gap-4">
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">HTTP<br /></p>
                    </div>
                    <vue-draggable-container
                      class="answerStandard dropzone dropzoneStandard answer-bg"
                      id="input12"
                    ></vue-draggable-container>
                  </div>
                </div>
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">HTTPS<br /></p>
                    </div>
                    <vue-draggable-container
                      class="answerStandard dropzone dropzoneStandard answer-bg"
                      id="input13"
                    ></vue-draggable-container>
                  </div>
                </div>
                <div class="p-2 answer-box">
                  <div class="center">
                    <p class="port-question-title">DHCP<br /></p>
                  </div>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg"
                    id="input14"
                  ></vue-draggable-container>
                </div>
                <div class="p-2 answer-box">
                  <div class="center">
                    <p class="port-question-title">DNS<br /></p>
                  </div>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg"
                    id="input15"
                  ></vue-draggable-container>
                </div>
                <div class="p-2 answer-box col-span-2">
                  <div class="center">
                    <p class="port-question-title">NetBIOS<br /></p>
                  </div>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg"
                    id="input16"
                  ></vue-draggable-container>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4" :style="{ display: currentPage === 1 ? '' : 'none!important' }">
            <div class="answer-container">
              <h1 class="heading-28 mb-2">Remote Access</h1>
              <div class="grid grid-cols-2 gap-4">
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">Telnet<br /></p>
                    </div>
                  </div>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg"
                    id="input17"
                  ></vue-draggable-container>
                </div>
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">sTelnet<br /></p>
                    </div>
                  </div>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg"
                    id="input18"
                  ></vue-draggable-container>
                </div>
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">SSH<br /></p>
                    </div>
                  </div>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg"
                    id="input19"
                  ></vue-draggable-container>
                </div>
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">RDP<br /></p>
                    </div>
                  </div>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg"
                    id="input20"
                  ></vue-draggable-container>
                </div>
              </div>
            </div>
            <div class="answer-container">
              <h1 class="heading-28 mb-2">Tunneling/VPN</h1>
              <div class="grid grid-cols-2 gap-4">
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">IPSec<br /></p>
                    </div>
                    <vue-draggable-container
                      class="answerStandard dropzone dropzoneStandard answer-bg"
                      id="input21"
                    ></vue-draggable-container>
                  </div>
                </div>
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">L2TP<br /></p>
                    </div>
                    <vue-draggable-container
                      class="answerStandard dropzone dropzoneStandard answer-bg"
                      id="input22"
                    ></vue-draggable-container>
                  </div>
                </div>
                <div class="p-2 answer-box col-span-2">
                  <div class="center">
                    <p class="port-question-title">PPTP<br /></p>
                  </div>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg"
                    id="input23"
                  ></vue-draggable-container>
                </div>
              </div>
            </div>
            <div class="answer-container">
              <h1 class="heading-28 mb-2">Network Management</h1>
              <div class="grid grid-cols-2 gap-4">
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">SNMP<br /></p>
                    </div>
                    <vue-draggable-container
                      class="answerStandard dropzone dropzoneStandard answer-bg"
                      id="input24"
                    ></vue-draggable-container>
                  </div>
                </div>
                <div class="p-2 answer-box">
                  <div class="port-inside">
                    <div class="center">
                      <p class="port-question-title">SQL Server<br /></p>
                    </div>
                    <vue-draggable-container
                      class="answerStandard dropzone dropzoneStandard answer-bg"
                      id="input25"
                    ></vue-draggable-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-sortable>
    <vs-popup :active.sync="remindPopup" class="pb-3" title="Have You Completed The Second Page?">
      <div class="flex justify-center mt-3 mb-5">
        <img src="https://connect-cdn.intellectualpoint.com/assets/images/icons/simulation-popup/danger.png" width="150" alt="Check" />
      </div>
      <div class="pl-2 pr-2">
        <h1 class="text-center font-bold my-6" style="font-size: 42px">Have You Completed The Second Page?</h1>
        <h3 class="text-center font-light mb-3">Use the Page Switcher at the bottom left hand of the screen to switch pages.</h3>
      </div>
      <div class="flex justify-center mt-3">
        <vs-button class="mt-5 mb-5 mr-1" color="danger" icon-pack="feather" icon="icon-x-circle" @click="markSimulationDenied" size="large"
          >No, I Haven't</vs-button
        >
        <vs-button
          class="mt-5 mb-5 ml-1"
          color="success"
          icon-pack="feather"
          icon="icon-check-circle"
          @click="markSimulationConfirmed"
          size="large"
          >Yes, I Have</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import shuffle from 'shuffle-array';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      currentPage: 0,
      remindPopup: false,

      ports: [
        'TCP 20/21',
        'TCP 22',
        'TCP 22',
        'TCP 69',
        'TCP 143',
        'TCP 25',
        'TCP 110',
        'TCP 636',
        'UDP 88',
        'UDP 1812',
        'TCP 49',
        'TCP 389',
        'TCP 80',
        'TCP 443',
        'UDP 67',
        'UDP 53',
        'UDP 138',
        'TCP 23',
        'TCP 22',
        'TCP 3389',
        'TCP 22',
        'TCP 1433',
        'UDP 500',
        'TCP 1701',
        'TCP 1723',
        'UDP 161',
      ],

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard-ports-practice currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    previous_page() {
      this.currentPage = 0;
    },
    next_page() {
      this.currentPage = 1;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          x.cancel();
        }
      }
    },
    markSimulation() {
      if (this.currentPage !== 1) {
        this.remindPopup = true;
        return this.$emit('simulation_getting_there', 0, false);
      }

      return this.markSimulationConfirmed();
    },
    markSimulationConfirmed() {
      this.remindPopup = false;
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'TCP 20/21') {
        totalScore++;
        document.getElementById('input0').style.borderColor = '#77FAAE';
        document.getElementById('input0').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input1').textContent === 'TCP 69') {
        totalScore++;
        document.getElementById('input1').style.borderColor = '#77FAAE';
        document.getElementById('input1').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input2').textContent === 'TCP 22') {
        totalScore++;
        document.getElementById('input2').style.borderColor = '#77FAAE';
        document.getElementById('input2').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input3').textContent === 'TCP 22') {
        totalScore++;
        document.getElementById('input3').style.borderColor = '#77FAAE';
        document.getElementById('input3').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input4').textContent === 'TCP 25') {
        totalScore++;
        document.getElementById('input4').style.borderColor = '#77FAAE';
        document.getElementById('input4').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input4').style.borderColor = '#fd4445';
        document.getElementById('input4').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input5').textContent === 'TCP 110') {
        totalScore++;
        document.getElementById('input5').style.borderColor = '#77FAAE';
        document.getElementById('input5').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input5').style.borderColor = '#fd4445';
        document.getElementById('input5').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input6').textContent === 'TCP 143') {
        totalScore++;
        document.getElementById('input6').style.borderColor = '#77FAAE';
        document.getElementById('input6').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input6').style.borderColor = '#fd4445';
        document.getElementById('input6').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input7').textContent === 'UDP 88') {
        totalScore++;
        document.getElementById('input7').style.borderColor = '#77FAAE';
        document.getElementById('input7').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input7').style.borderColor = '#fd4445';
        document.getElementById('input7').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input8').textContent === 'TCP 49') {
        totalScore++;
        document.getElementById('input8').style.borderColor = '#77FAAE';
        document.getElementById('input8').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input8').style.borderColor = '#fd4445';
        document.getElementById('input8').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input9').textContent === 'UDP 1812') {
        totalScore++;
        document.getElementById('input9').style.borderColor = '#77FAAE';
        document.getElementById('input9').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input9').style.borderColor = '#fd4445';
        document.getElementById('input9').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input10').textContent === 'TCP 389') {
        totalScore++;
        document.getElementById('input10').style.borderColor = '#77FAAE';
        document.getElementById('input10').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input10').style.borderColor = '#fd4445';
        document.getElementById('input10').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input11').textContent === 'TCP 636') {
        totalScore++;
        document.getElementById('input11').style.borderColor = '#77FAAE';
        document.getElementById('input11').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input11').style.borderColor = '#fd4445';
        document.getElementById('input11').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input12').textContent === 'TCP 80') {
        totalScore++;
        document.getElementById('input12').style.borderColor = '#77FAAE';
        document.getElementById('input12').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input12').style.borderColor = '#fd4445';
        document.getElementById('input12').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input13').textContent === 'TCP 443') {
        totalScore++;
        document.getElementById('input13').style.borderColor = '#77FAAE';
        document.getElementById('input13').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input13').style.borderColor = '#fd4445';
        document.getElementById('input13').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input14').textContent === 'UDP 67') {
        totalScore++;
        document.getElementById('input14').style.borderColor = '#77FAAE';
        document.getElementById('input14').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input14').style.borderColor = '#fd4445';
        document.getElementById('input14').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input15').textContent === 'UDP 53') {
        totalScore++;
        document.getElementById('input15').style.borderColor = '#77FAAE';
        document.getElementById('input15').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input15').style.borderColor = '#fd4445';
        document.getElementById('input15').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input16').textContent === 'UDP 138') {
        totalScore++;
        document.getElementById('input16').style.borderColor = '#77FAAE';
        document.getElementById('input16').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input16').style.borderColor = '#fd4445';
        document.getElementById('input16').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input17').textContent === 'TCP 23') {
        totalScore++;
        document.getElementById('input17').style.borderColor = '#77FAAE';
        document.getElementById('input17').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input17').style.borderColor = '#fd4445';
        document.getElementById('input17').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input18').textContent === 'TCP 22') {
        totalScore++;
        document.getElementById('input18').style.borderColor = '#77FAAE';
        document.getElementById('input18').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input18').style.borderColor = '#fd4445';
        document.getElementById('input18').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input19').textContent === 'TCP 22') {
        totalScore++;
        document.getElementById('input19').style.borderColor = '#77FAAE';
        document.getElementById('input19').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input19').style.borderColor = '#fd4445';
        document.getElementById('input19').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input20').textContent === 'TCP 3389') {
        totalScore++;
        document.getElementById('input20').style.borderColor = '#77FAAE';
        document.getElementById('input20').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input20').style.borderColor = '#fd4445';
        document.getElementById('input20').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input21').textContent === 'UDP 500') {
        totalScore++;
        document.getElementById('input21').style.borderColor = '#77FAAE';
        document.getElementById('input21').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input21').style.borderColor = '#fd4445';
        document.getElementById('input21').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input22').textContent === 'TCP 1701') {
        totalScore++;
        document.getElementById('input22').style.borderColor = '#77FAAE';
        document.getElementById('input22').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input22').style.borderColor = '#fd4445';
        document.getElementById('input22').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input23').textContent === 'TCP 1723') {
        totalScore++;
        document.getElementById('input23').style.borderColor = '#77FAAE';
        document.getElementById('input23').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input23').style.borderColor = '#fd4445';
        document.getElementById('input23').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input24').textContent === 'UDP 161') {
        totalScore++;
        document.getElementById('input24').style.borderColor = '#77FAAE';
        document.getElementById('input24').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input24').style.borderColor = '#fd4445';
        document.getElementById('input24').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input25').textContent === 'TCP 1433') {
        totalScore++;
        document.getElementById('input25').style.borderColor = '#77FAAE';
        document.getElementById('input25').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input25').style.borderColor = '#fd4445';
        document.getElementById('input25').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
    markSimulationDenied() {
      this.$vs.notify({
        title: 'Complete The Second Page',
        text: 'Once you have completed the second page, press submit to score your simulation.',
        color: 'warning',
        position: 'bottom-right',
        icon: 'feather',
        iconPack: 'icon-info',
      });
      this.remindPopup = false;
    },
  },
  components: {
    draggable,
    Prism,
    shuffle,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.ports = shuffle(this.ports);
  },
};
</script>

<style lang="scss" scoped>
.currently-dragging-standard-ports-practice {
  background-color: #02153d !important;
  border: 1px solid #0053ff !important;
  padding: 12px;
  color: white;
  z-index: 99;
  min-width: auto;
}

#wordBank {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
}

.ports-block {
  display: flex;
  max-height: 100px;
  padding: 10px;
  text-align: left;
  color: #fff;
  background-color: #02153d;
  border: 1px solid #0053ff;
  border-radius: 5px;
}

.answer-container {
  background-color: #02050d;
  border: 1px solid #181d2a;
  border-radius: 0.6rem;
  padding: 1rem;
}

.answer-box {
  background-color: #040815;
  border: 1px solid #181d2a;
  border-radius: 0.6rem;
}
</style>
